.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: #ddfbff; /* Change to your desired color */
  background-size: cover; /* Cover entire page */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating the image */
  margin: 0; /* Removes any default body margin */
}

.centered {
  justify-content: center;  /*Horizontally centers the content */
  /*align-items: center;  Vertically centers the content */
  /*height: 100vh;  Full viewport height */
}

.list {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove default padding from the list */
  margin: 0; /* Remove default margin from the list */
}

.list-item {
  display: block; /* Stack elements vertically */
  margin-bottom: 1px; /* Smaller space between items */
  text-align: left; /* Align content to the left */
}

.list-image {
  display: inline-block; /* Align image and text inline */
  vertical-align: middle; /* Align image with text */
  margin-right: 8px; /* Space between image and text */
}

.list-text {
  display: inline-block; /* Align text inline with image */
  vertical-align: middle; /* Align text with the image */
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}